$(function() {
  $('.sw-VideoA_Heading').matchHeight();
  $('.sw-VideoA_Text').matchHeight();

  var $refreshButton = $('#refresh');
  var $results = $('#css_result');

  function refresh(){
    var css = $('style.cp-pen-styles').text();
    $results.html(css);
  }

  refresh();
  $refreshButton.click(refresh);

  // Select all the contents when clicked
  $results.click(function(){
    $(this).select();
  });


}());
