$(function() {
  $('.sw-AccordionA').accordion({
    'tabs': '.sw-AccordionA_Tab',
    'tabpanels': '.sw-AccordionA_Panel',
    'useRole': true,
    'openFirstChild': true,
    'multiselectable': true,
    'tabClass': 'sw-AccordionA_Tab-active',
    'panelClass': 'sw-AccordionA_Panel-active'
  });
}());

