$(function() {
  /**
   * .js-auto-add-pagelink内にh2があればページ内リンクを作成する。
   */
  if($('.js-auto-add-pagelink h2').length) {
    function autoAddPagelink() {
      var $parent = $('.js-auto-add-pagelink');
      var $headings = $parent.find('h2');
      var listElements = '';
      var notExistElements;
      if($('.sw-Pagelink').length) {
        notExistElements = false;
      } else {
        notExistElements = true;
      }

      /**
       * h2のテキストを取得、hrefは連番、liタグ用のテキストを`listElements`に格納。
       * .js-auto-add-pagelink直下に要素を出力する。
       */
      if(notExistElements) {
        $headings.each(function(i) {
          var index = i + 1;

          // 見出し要素がある場合、そちらのIDを優先して使用。
          var id = $(this).attr('id');
          if (!id) {
            // idがない場合、見出しとアンカーリンク用のidを連番で生成。
            id = 'ref' + index;
            $(this).attr('id', 'ref' + index);
          }

          listElements += '<li class="sw-Pagelink_Item"><a class="sw-Pagelink_Link js-smoothScroll" href="#' + id + '"><span class="sw-Pagelink_Icon" aria-hidden="true"></span>' + $(this).text() + '</a></li>';
        });
        $parent.prepend('<ul class="sw-Pagelink">' + listElements + '</ul>');

        /**
         * 1カラムのときだけ、サイドメニューへのリンクを追加する。
         */
        if(breakpoint.value === '' || breakpoint.value === 'md')　{
          if (0 < $('#InquiryForm').length) {
            $('.sw-Pagelink').append('<li class="sw-Pagelink_Item"><a class="sw-Pagelink_Link js-smoothScroll" href="#InquiryForm"><span class="sw-Pagelink_Icon" aria-hidden="true"></span>Inquiry Form</a></li>')
          }
        }
      }
    }
    $(window).on('load resize', function() {
      autoAddPagelink();
    });
  }

}());
