/**
 * ニュースサイドバーのカレント表示を切り替えます。
 */
$(function() {
  // set current ... year
  if(location.pathname.match(/\/news\//g)) {
    if(location.pathname.match(/\/news\/$/g) || location.pathname.match(/\/news\/[^\/]*\.html$/g)) {
      $('.l-DetailTemplate_SubNav a[href$="/news/"]').attr('aria-current', 'location');
    } else {
      var current = location.pathname.replace(/^.*\/news\/([^\/]{4})\/.*$/g, '$1');
      $('.l-DetailTemplate_SubNav a[href*="/news/' + current + '/"]').attr('aria-current', 'location');
    }
  }

  // set current ... category
  $(".st-CategoryNavNews").each(function() {
    var currentCategory = location.pathname.replace( /^\/news\/([^\/]*)\/.*$/g, "$1" );
    $(".st-SideNavNews_ParentLink, .st-CategoryNavNews_Link").removeAttr(
      "aria-current"
    );
    if(location.pathname.match("^\/news\/(.*)\/.*$", "g")) {
      $(
        ".st-SideNavNews_ParentLink[href*=" +
        currentCategory +
        "], .st-CategoryNavNews_Link[href*=" +
        currentCategory +
        "]"
      ).attr("aria-current", "location");
    } else {
      $(".st-CategoryNavNews_Link:first").attr("aria-current", "location");
    }
  });

  // mobile menu toggle
  $(".st-CategoryNavNews").each(function() {
    var $parent = $(this);
    var $trigger = $parent.find(".st-CategoryNavNews_Parent");
    var $body = $parent.find(".st-CategoryNavNews_List");

    function categoryExpand() {
      var expanded = $parent.attr("aria-expanded") == "true";
      $parent.attr("aria-expanded", expanded ? "false" : "true");
      $body.attr("aria-hidden", expanded ? "true" : "false");
    }

    $trigger.on("click", categoryExpand);
    categoryExpand();
  });
});
