/**
 * Cookie取得の同意エリアです。
 * 利用者が許可をするまで画面下部に固定されます。
 */
$(function() {
  var allowCookie = document.getElementById('st-AllowCookie');
  if (!allowCookie) return;
  var allowCookieButton = document.getElementById('st-AllowCookie_Button');
  var cookie = document.cookie;
  var PERMISSION_NAME = 'cookie_permission=true';
  var isCookieAllowed = checkCookieAllowed();

  var init = function() {
    if (isCookieAllowed) {
      hide();
    } else {
      show();
      focus();
    }

    onClickAllow();
  }();

  function show() {
    allowCookie.style.bottom = '0';
  }

  function hide() {
    allowCookie.style.display = 'none';
  }

  function focus() {
    allowCookie.setAttribute('tabindex', '0');
    allowCookie.focus();
  }

  function removeFocus() {
    allowCookie.setAttribute('tabindex', '-1');
  }

  function checkCookieAllowed() {
    if (cookie.indexOf(PERMISSION_NAME) !== -1) {
      return true;
    }
    return false;
  }

  function onClickAllow() {
    allowCookieButton.addEventListener('click', function(e) {
      removeFocus();
      hide();
      setCookie();
      e.stopPropagation();
    });
  }

  function setCookie() {
    document.cookie = PERMISSION_NAME;
  }

  function deleteCookie() {
    document.cookie = PERMISSION_NAME + '; max-age=0';
  }

}());
