$(function() {
  var $controls = $('.home-Carousel_ThumbnailLink, .home-Carousel_Control li');

  var carousel = $('.home-Carousel_Items').bxSlider({
    // 初期表示をランダムで始める
    randomStart: true,
    mode: 'fade',
    // PrevとNextを表示する
    controls: true,
    // スライドを自動で切り替える
    auto: false,
    // スライドの停止時間
    pause: 4000,
    // マウスオンするとスライドが一時停止する
    autoHover: true,
    // Prev,Nextのコントロール
    pagerSelector: '.home-Carousel_Control',
    prevSelector: '.home-Carousel_ControlPrev',
    nextSelector: '.home-Carousel_ControlNext',
    // 各スライドのコントロール
    pagerCustom: '.home-Carousel_Thumbnails'
  });

  // コントロールをマウスオンするとスライドが一時停止する
  $controls.hover(
    function() {
      carousel.stopAuto();
    },
    function() {
      carousel.startAuto();
    }
  );

});
