/**
 * ニュースの月切り替えタブ
 */
$(function() {
  // set current
  $(".news-BoxA_Filter").each(function() {
    var month = location.pathname.replace(/^.*\/\d{4}(\/\d{2}\/).*$/g, "$1");
    var isMonth = new RegExp("^.*\\/\\d{4}\\/\\d{2}\\/.*$", "g");

    $(".news-BoxA_FilterLink").each(function(i, o) {
      if ( !location.pathname.match(isMonth) ) {
        if (!$(o).attr("href").match(isMonth)) {
            $(o).attr("aria-current", "location");
          }
      } else if ( $(o).attr("href").match(new RegExp(month), "g") ) {
        $(o).attr("aria-current", "location");
        if (window.innerWidth < 768) {
          $(".news-BoxA_Filter").scrollLeft( $(o).offset().left );
        }
      } else {
        $(o).removeAttr("aria-current");
      }
    });
  });

  // mobile menu set scrollX
  $(".news-BoxA_Header").each(function() {
    var $parent = $(this);
    var $filter = $(".news-BoxA_Filter");

    function getScrollPosition(e) {
      var l = $filter.scrollLeft();
      var endPoint = $filter[0].scrollWidth - window.innerWidth;

      $parent[l >= endPoint ? "addClass" : "removeClass"]("is-Last");
      $parent[!l ? "addClass" : "removeClass"]("is-First");
    }

    $filter.on("scroll", getScrollPosition);
    getScrollPosition();
  });
});
