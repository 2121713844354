/**
 * 下層ページのスマホで使用するサイドドロワーです。
 */
$(function() {
  var $drawerNav = $('.st-DrawerNav_Item');

  if (!$drawerNav.length) return;

  $drawerNav.each(function(i) {
    var $this = $(this);
    var $trigger = $this.find('.st-DrawerNav_Trigger');
    var $drawer = $('#' + $trigger.attr('aria-controls'));
    var $back = $drawer.find('.st-DrawerNav_DrawerBack');

    if (!$drawer.length) return;

    $trigger.on('click', function(e) {
      $(this).attr('aria-expanded', 'true');
      $drawer.attr('aria-hidden', 'false');
      $('.st-GlobalNav_Area').css('overflow-y', 'hidden');

      e.preventDefault();
    });

    $back.on('click', function(e) {
      $trigger.attr('aria-expanded', 'false');
      $drawer.attr('aria-hidden', 'true');
      $('.st-GlobalNav_Area').css('overflow-y', '');
      e.stopPropagation();
    });

  });

}());
