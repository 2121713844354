/**
 * サイドバーのカレント表示やトグルを切り替えます。
 */
$(function() {
  var $toggle = $('#st-Sitemap_Toggle');
  var $products = $('#st-Sitemap_Products');
  var stopWidth = '1024';
  var wait = (1000 / 60); // 60fps

  var init = function() {
    toggleExpand();
    stopToggle();

    $(window).on('resize', _.throttle(function() {
      stopToggle();
    }, wait));
  }();

  /**
   * クリック時にトグルを切り替えます。
   */
  function toggleExpand() {
    $toggle.on('click', function(e) {
      var isExpanded = $(this).attr('aria-expanded') === 'true';

       if (isExpanded) {
        $(this).attr('aria-expanded', 'false');
        $products.attr('aria-hidden', 'true');
      } else {
        $(this).attr('aria-expanded', 'true');
        $products.attr('aria-hidden', 'false');
      }

      e.stopPropagation();
    });
  }

  /**
   * トグルを停止します。
   */
  function stopToggle() {
    var windowSize = window.outerWidth;
    var expanded = $toggle.attr('aria-expanded') === 'true';

    if (windowSize > stopWidth) {
      $toggle.attr('tabindex', '-1');
      $products.attr('aria-hidden', 'false');
    } else {
      $toggle.attr('tabindex', '');
      $products.attr('aria-hidden', !expanded);
    }
  }


});
