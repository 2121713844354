$(function() {
  /**
   * テーブルの横スクロールができる状態の時にヒントを表示します。
   * タッチ操作できるかどうかで表示する要素を切り替えます。
   */
  if ($('.sw-TableA-scroll').length) {
    $('.sw-TableA-scroll').each(function() {
      var $parent = $(this);
      var $table = $parent.find('table');
      var isScrolled = checkScrolled(); // スクロールできる場合はtrue
      var isTouchScreen = checkTouchScreen(); // タッチスクリーンの場合はtrue
      var wait = (1000 / 60); // 60fps

      function createHint() {
        $parent.before('<div class="sw-TableA_Hint"><p class="sw-TableA_HintSp"><img class="sw-TableA_IconLeft" src="/assets/img/common/icon_slide-arrow-left.svg" alt="">You can scroll left and right<img class="sw-TableA_IconRight" src="/assets/img/common/icon_slide-arrow-right.svg" alt=""></p><p class="sw-TableA_HintPc"><img class="sw-TableA_IconLeft" src="/assets/img/common/icon_slide-arrow-left.svg" alt="">You can scroll left and right (shift + scroll)<img class="sw-TableA_IconRight" src="/assets/img/common/icon_slide-arrow-right.svg" alt=""></p></div>');
      }

      function checkScrolled() {
        var parentWidth = $parent.outerWidth();
        var tableWidth = $table.get(0).scrollWidth;
        return (tableWidth > parentWidth) ? true : false;
      }

      function checkTouchScreen() {
        return (window.ontouchstart === null) ? true : false;
      }

      function toggleHint() {
        if (isScrolled) {
          $parent.prev().show();
        } else {
          $parent.prev().hide();
        }

        if (isTouchScreen) {
          $parent.prev().find('.sw-TableA_HintSp').show();
          $parent.prev().find('.sw-TableA_HintPc').hide();
        } else {
          $parent.prev().find('.sw-TableA_HintSp').hide();
          $parent.prev().find('.sw-TableA_HintPc').show();
        }
      }

      var init = function() {
        createHint();
        toggleHint();
      }();

      $(window).on('scroll', _.throttle(function() {
        isScrolled = checkScrolled();
        isTouchScreen = checkTouchScreen();
        toggleHint();
      }, wait));
    });
  }

}());
