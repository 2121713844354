/**
 * ページ内リンクを生成します。
 * リンクをクリックするとスムーススクロールします（）。
 */
$(function() {
  var $pageNav = $('.sw-PageNavA');
  var $headings = $pageNav.find('h2');

  if (!$pageNav.length) return;
  if (!$headings.length) return;

  var init = function() {
    createTOC();
  }();

  function createTOC() {
    var list = '';

    $headings.each(function(i) {
      var index = i + 1;
      var headingID = $(this).attr('id');

      if (!headingID) {
        headingID = 'ref' + index;
        $(this).attr('id', 'ref' + index);
      }

      list += '<li class="sw-PageNavA_Item"><a class="sw-PageNavA_Link js-smoothScroll" href="#' + headingID + '"><span class="sw-PageNavA_Icon" aria-hidden="true"></span>' + $(this).text() + '</a></li>';
    });

    $pageNav.prepend('<ul class="sw-PageNavA_List">' + list + '</ul>');
  }

}());
