$(function() {
  var set_customselect = function($target) {
    var _this = this;
    var $trigger = $target.querySelector("button");
    var $region = $target.querySelector(".st-RegionSelect_Region");
    var $select = $target.querySelector("select");
    var $option = [].slice.call($select.querySelectorAll("option"));

    if (!$trigger) return;
    if (!$select) return;
    if (!$option[0]) return;

    _this.$target = $target;
    _this.$region = $region;
    _this.$trigger = $trigger;
    _this.$select = $select;
    _this.$option = $option;
    _this.current = null;
    _this.cursor = null;
    _this.active = false;
    _this.init();
  };

  set_customselect.prototype.init = function() {
    var _this = this;

    // event
    _this.chante_eve = document.createEvent("Event");
    _this.chante_eve.initEvent("change", false, false);

    // event
    _this.click_eve = document.createEvent("Event");
    _this.click_eve.initEvent("click", false, false);

    // change
    _this.$select.addEventListener("change", _this.act_change.bind(_this), false);
    _this.$select.dispatchEvent(_this.chante_eve);

    _this.$mock = document.createElement("ul");
    _this.$mock.className = 'st-RegionSelect_Mock';
    _this.$mock_option = [];

    _this.$option.forEach(function(obj, index) {
      var $item = document.createElement("li");
      $item.innerHTML = obj.innerHTML;
      $item.setAttribute("data-order", index);
      if (obj.selected) $item.classList.add("is-selected");

      $item.addEventListener("click", _this.set_value.bind(_this), false);
      $item.addEventListener("mouseover", _this.set_hover.bind(_this), false);
      _this.$mock_option.push($item);

      _this.$mock.appendChild($item);
    });

    _this.$target.appendChild(_this.$mock);

    _this.$trigger.addEventListener("click", _this.set_toggle.bind(_this), false);

    _this.cancelTimer = null;
    _this.$trigger.addEventListener("blur", _this.cancel.bind(_this), false);

    window.addEventListener("keyup", _this.set_cursor.bind(_this), false);
  };

  // select mock option
  set_customselect.prototype.set_hover = function(e) {
    var _this = this;
    _this.cursor = +e.currentTarget.getAttribute("data-order");
    _this.$mock.setAttribute("data-cursor", _this.cursor);
  };
  set_customselect.prototype.set_value = function(e) {
    var _this = this;
    _this.$option[+e.currentTarget.getAttribute("data-order")].selected = true;
    _this.$select.dispatchEvent(_this.chante_eve);

    var click_eve = document.createEvent("Event");
    click_eve.initEvent("click", false, false);
    _this.$trigger.dispatchEvent(click_eve);
  };

  // change option
  set_customselect.prototype.act_change = function(e) {
    var _this = this;
    _this.$option.forEach(function(o, i) {
      if (o.selected) {
        _this.$region.innerHTML = o.innerHTML;
        e.currentTarget.setAttribute("data-selected", i);
        _this.current = i;
        _this.cursor = i;
      }
    });
  };

  // toggle
  set_customselect.prototype.set_toggle = function(e) {
    var _this = this;

    // toggle
    var toggle = e.currentTarget.getAttribute("data-toggle");
    e.currentTarget.setAttribute("data-toggle", +toggle ? 0 : 1);
    _this.active = +toggle ? 0 : 1;

    if (+toggle === 0) {
      _this.cursor = _this.current;
      _this.$mock.setAttribute("data-cursor", _this.current);
      _this.item_h = _this.$mock_option[0].clientHeight;
    }

    // scroll
    _this.$mock.scrollTop = (0, _this.item_h * _this.current);

    // position
    var top = e.currentTarget.getBoundingClientRect().top;
    var bottom = window.innerHeight - top - e.currentTarget.clientHeight;
    e.currentTarget.setAttribute("data-position", top < bottom ? 1 : 0);

    _this.$mock.style.setProperty(
      "max-height",
      (top > bottom ? top : bottom) - 16 + "px"
    );
  };

  // toggle
  set_customselect.prototype.cancel = function(e) {
    var _this = this;
    var timer = setTimeout(function() {
      _this.$trigger.removeAttribute("data-position");
      _this.$trigger.setAttribute("data-toggle", 0);
      _this.active = false;
      _this.cursor = _this.current;
      _this.$mock.setAttribute("data-cursor", _this.current);

      clearTimeout(timer);
    }, 500);
  };

  set_customselect.prototype.set_cursor = function(e) {
    var _this = this;
    var act_shift = function(shift) {
      _this.cursor = _this.cursor + shift;
      _this.$mock.setAttribute("data-cursor", _this.cursor);
      _this.$mock.scrollTop = (0, _this.item_h * _this.cursor);
    };
    if (e.keyCode == 13) {
      _this.$option[_this.cursor].selected = true;
      _this.$select.dispatchEvent(_this.chante_eve);
    }
    if (_this.active) {
      if (e.keyCode == 38 && _this.cursor > 0) act_shift(-1);
      if (e.keyCode == 40 && _this.cursor < _this.$option.length - 1)
        act_shift(1);
    }
  };

  var customselect = [].slice.call(document.querySelectorAll(".st-RegionSelect"));
  customselect.forEach(function(obj, index) {
    var cs = new set_customselect(obj);
  });

  // judge touche screen
  document
    .querySelector("body")
    .classList["ontouchstart" in window ? "add" : "remove"]("is-touche");

  // 選択したRegionによってページ遷移する
  // 現在のサイトであるEuropeはページ遷移なしとしている
  var select = document.querySelectorAll('.st-RegionSelect_Select:not(.focus-ring)')[0];
  if (!select) return;
  select.addEventListener('change', function(e) {
    var selectNumber = select.dataset.selected;
    var regionURL = {
      0: 'https://europe.kyocera.com/', // Europe
      1: 'https://austria.kyocera.com/', // Austria
      2: 'https://denmark.kyocera.com/', // Denmark
      3: 'https://finland.kyocera.com/', // Finland
      4: 'https://france.kyocera.com/', // France
      5: 'https://germany.kyocera.com/', // Germany
      6: 'https://italy.kyocera.com/', // Italy
      7: 'https://netherlands.kyocera.com/', // Netherlands
      8: 'https://norway.kyocera.com/', // Norway
      9: 'https://portugal.kyocera.com/', // Portugal
      10: 'https://russia.kyocera.com/', // Russia
      11: 'https://spain.kyocera.com/', // Spain
      12: 'https://sweden.kyocera.com/', // Sweden
      13: 'https://switzerland.kyocera.com/', // Switzerland
      14: 'https://southafrica.kyocera.com/', // Africa
      15: 'https://uk.kyocera.com/' // United Kingdom
    };

    if (selectNumber !== '0') {
      window.open(regionURL[selectNumber], '_blank');
    }

    e.stopPropagation();
  });

});

