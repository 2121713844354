/**
 * リニューアル前のアーカイブニュースのトグルを再現します。
 */
$(function() {
  $('.navbar-toggle').attr('aria-expanded', 'false');

  $('.navbar-toggle').on('click', function(e) {
    var $this = $(this)
    var $target = $($this.attr('data-target'));
    var openClass = 'in';
    var isOpen = $target.hasClass(openClass) ? true: false;

    if (isOpen) {
      $this.attr('aria-expanded', 'false');
      $target.slideUp().removeClass(openClass).attr('aria-hidden', 'true');
      return;
    }

    $this.attr('aria-expanded', 'true');
    $target.slideDown().addClass(openClass).attr('aria-hidden', 'false');
  });
});

