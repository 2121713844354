/**
 * Divisionで使用するドロップダウンナビゲーションです。
 * .st-DropGlovalNavと違い、複数配置することができます。
 */
$(function() {
  var $dropdown = $('.st-DropNav');
  // ドロップダウンが存在しない場合は終了する
  if(!$dropdown.length) return;

  var $window = $(window);
  var $close = $('#st-DropNav_Close');
  var mediaQuery = window.matchMedia('(min-width: 1024px)');
  var slideSpeed = 300;
  var EXCLUDED_CLASS = [
    // このclassを持つ要素をクリックした時はドロップダウンを閉じない
    'st-GlobalHeader_Buttons-devision',
    'st-DropNav',
    'st-DropNav_List',
    'st-DropNav_PcButton'
  ];
  var keys = {
    tab: 9
  };

  $dropdown.each(function(i) {
    // 該当するdropdown（本体をラップしている）
    var $this = $(this);
    // PCのトリガーになるボタン
    var $dropdownButtonPc = $('#' + $(this).attr('aria-labelledby'));
    // PCの閉じるボタン
    var $closeButton = $this.find('.st-DropNav_Close');
    var $firstLink = $this.find('a').eq(0);
    var $lastLink = $this.find('a').eq(-1);
    var $nextNav = $('.st-DropNav_PcButton' + '[aria-controls="' + $('#st-DropNav' + (i + 2)).attr('id') + '"]').length ? $('.st-DropNav_PcButton' + '[aria-controls="' + $('#st-DropNav' + (i + 2)).attr('id') + '"]') : $('#st-SearchButton');
    // SPのトリガーになるボタン
    var $dropdownButtonSp = $this.find('.st-DropNav_SpButton');
      // SPで展開するエリア
    var $list = $this.find('.st-DropNav_List');

    // 初期表示
    var init = function() {
      initAttr();
      dropdownSp();
      dropdownPc();
      hideDropdownPc();
      focusFirstLink();
      focusCurrentDropNav();
      focusNextNav();

      if (mediaQuery.matches) {
        pcAttr();
      } else {
        spAttr();
      }

      // ブレイクポイントが切り替わった時にDOMを変更する
      mediaQuery.addListener(changeBreakpoint);
    }();

    // ブレイクポイントが切り替わった時にaria属性などを変更する
    function changeBreakpoint() {
      if (mediaQuery.matches) {
        pcAttr();
      } else {
        spAttr();
      }
    }

    // SP版のドロップダウン
    function dropdownSp() {
      $dropdownButtonSp.on('click', function(e) {
        var isExpanded = $dropdownButtonSp.attr('aria-expanded') === 'true';

        if (isExpanded) {
          $dropdownButtonSp.attr('aria-expanded', 'false');
          $list.attr('aria-hidden', 'true').slideUp(slideSpeed);
        } else {
          $dropdownButtonSp.attr('aria-expanded', 'true');
          $list.attr('aria-hidden', 'false').slideDown(slideSpeed);
        }
        e.stopPropagation();
      });
    }

    // PC版のドロップダウン
    function dropdownPc() {
      $dropdownButtonPc.on('click', function(e) {
        var isExpanded = $dropdownButtonPc.attr('aria-expanded') === 'true';

        if (isExpanded) {
          $dropdownButtonPc.attr('aria-expanded', 'false');
          $this.attr('aria-hidden', 'true').slideUp(slideSpeed);
          hideClose();
        } else {
          // 他のdropdownで開いているものを閉じる
          $('.st-DropNav_PcButton').attr('aria-expanded', 'false');
          $('.st-DropNav').attr('aria-hidden', 'true').slideUp(slideSpeed);
          $dropdownButtonPc.attr('aria-expanded', 'true');
          $this.attr('aria-hidden', 'false').slideDown(slideSpeed);
          showClose();
        }
      });
    }

    // CloseボタンでPC版のドロップダウンを非表示にする
    function hideDropdownPc() {
      $close.on('click', function(e) {
        $dropdownButtonPc.attr('aria-expanded', 'false');
        $this.attr('aria-hidden', 'true').slideUp(slideSpeed);
        hideClose();
        e.stopPropagation();
      })
    }

    // DropNavをTabキーを押すとナビゲーション内にフォーカスする
    function focusFirstLink() {
      $dropdownButtonPc.on('keydown', function(e) {
        if(!e.shiftKey && e.which === keys.tab) {
          setTimeout(function() {
            $firstLink.focus().addClass('focus-ring');
          });
        }
      });
    }

    // ナビゲーション内の最後のリンクでShift + Tabキーを押すと、DropNavにフォーカスする（戻る）
    function focusCurrentDropNav() {
      $firstLink.on('keydown', function(e) {
        if (e.shiftKey && e.which === keys.tab) {
          setTimeout(function() {
            $dropdownButtonPc.focus().addClass('focus-ring');
          });
        }
      });
    }

    // ナビゲーション内の最後のリンクでTabキーを押すと、次のDropNavか検索ボタンにフォーカスする
    function focusNextNav() {
      $lastLink.on('keydown', function(e) {
        if (!e.shiftKey && e.which === keys.tab) {
          e.preventDefault();
          setTimeout(function() {
            $nextNav.focus().addClass('focus-ring');
          });
        }
      });
    }

    // Closeボタンを表示する
    function showClose() {
      $close.show().attr('aria-hidden', 'false');
    }

    // Closeボタンを非表示する
    function hideClose() {
      $close.hide().attr('aria-hidden', 'true');
    }

    // WAI-ARIAを付与する（すべて閉じている状態）
    function initAttr() {
      $this.attr('aria-hidden', 'true');
      $dropdownButtonSp.attr('aria-expanded', 'false');
      $list.attr('aria-hidden', 'true');
      $dropdownButtonPc.attr('aria-expanded', 'false');
      $closeButton.attr('aria-hidden', 'true');
    }

    // WAI-ARIAを変更する
    function pcAttr() {
      $this.attr('aria-hidden', 'true');
      $dropdownButtonSp.attr('aria-expanded', 'false');
      $list.attr('aria-hidden', 'false');
      $dropdownButtonPc.attr('aria-expanded', 'false');
      $closeButton.attr('aria-hidden', 'true');
    }

    // WAI-ARIAを変更する
    function spAttr() {
      $this.attr('aria-hidden', 'false').show();
      $dropdownButtonSp.attr('aria-expanded', 'false');
      $list.attr('aria-hidden', 'true');
      $dropdownButtonPc.attr('aria-expanded', 'false');
      $closeButton.attr('aria-hidden', 'true');
    }

  });

}());
